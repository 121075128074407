<script setup lang="ts">
import dayjs from 'dayjs';
import { useI18n } from 'vue-i18n';
import type { Incident } from '~/types/incident';

const { locale } = useI18n();

defineProps<{
  incident: Incident;
}>();
</script>
<template>
  <ui-card>
    <div class="grid grid-cols-8 gap-4">
      <!-- Root Cause -->
      <div class="flex col-span-4 items-center">
        <label class="text-gray-400 text-sm mr-4">{{ $t('global.root_cause') }}: </label>
        <ui-badge color="orange">
          {{ $t(`incidents.type_${incident.type}`) }}
        </ui-badge>
      </div>

      <!-- Time Start -->
      <div class="flex items-center col-span-2">
        <label class="text-gray-400 text-sm mr-4 whitespace-nowrap">{{ $t('global.start') }}: </label>
        <p class="text-[#52525B] text-sm ml-2 whitespace-nowrap">
          {{
            dayjs(incident.start)
              .locale(locale)
              .format(`dddDD MMM YYYY ${$t('global.at')} HH:mm`)
          }}
        </p>
      </div>

      <!-- Time End -->
      <div class="flex items-center col-span-2">
        <label class="text-gray-400 text-sm mr-4 whitespace-nowrap">{{ $t('global.end') }}: </label>
        <p class="text-[#52525B] text-sm ml-2 whitespace-nowrap">
          {{
            dayjs(incident.end)
              .locale(locale)
              .format(`dddDD MMM YYYY ${$t('global.at')} HH:mm`)
          }}
        </p>
      </div>

      <!-- Owner -->
      <div class="flex items-center col-span-3">
        <label class="text-gray-400 text-sm mr-4">{{ $t('incidents.owner') }}: </label>
        <div class="flex items-center">
          <p class="text-[#52525B] text-sm ml-2">
            {{ `${incident.owner_user.first_name} ${incident.owner_user.last_name}` }}
          </p>
        </div>
      </div>

      <!-- Reporter -->
      <div class="flex items-center col-span-3">
        <label class="text-gray-400 text-sm mr-4">{{ $t('incidents.reported_by') }}: </label>
        <div v-if="incident.alert_id" class="flex items-center">
          <ui-avatar photo-url="/logo.jpg" />
          <p class="text-[#52525B] text-sm ml-2">NRJx</p>
        </div>
        <div v-else class="flex items-center">
          <p v-if="incident.source_user" class="text-[#52525B] text-sm ml-2">
            {{ incident.source_user ? `${incident.source_user.first_name} ${incident.source_user.last_name}` : ' --' }}
          </p>
        </div>
      </div>

      <!-- Root Cause -->
      <div class="flex col-span-2 items-center">
        <label class="text-gray-400 text-sm mr-4">{{ $t('global.status') }}: </label>
        <ui-badge color="orange">
          {{ $t(`incidents.statuses.${incident.status}`) }}
        </ui-badge>
      </div>
    </div>
  </ui-card>
</template>
