<script setup lang="ts">
import * as yup from 'yup';
import { userStore } from '~/stores/user';
import { useForm } from 'vee-validate';
import dayjs from 'dayjs';
import type { Incident } from '~/types/incident';
import { useScroll } from '@vueuse/core';
import type { User } from '~/types/user';

// Props and emits
const props = defineProps<{
  incident: Incident;
}>();

// Data
const scrollView = ref<HTMLElement | null>(null);
const { y } = useScroll(scrollView, { behavior: 'smooth', offset: { bottom: 0 } });

// Form
const schema = yup.object({
  comment: yup.string(),
});
const { handleSubmit, resetField } = useForm<{ comment: string }>({
  validationSchema: schema,
});

// Function
const getUser = (user_id: string): User => {
  return userStore().getUser(user_id);
};

const comment = handleSubmit(async (values) => {
  if (values.comment === '') return;
  await incidentStore().insertComment(props.incident.id, values.comment);
  resetField('comment');
  if (scrollView.value) {
    y.value = scrollView.value.scrollHeight - scrollView.value.clientHeight;
  }
});

onMounted(() => {
  if (scrollView.value) {
    y.value = scrollView.value.scrollHeight - scrollView.value.clientHeight;
  }
});
</script>
<template>
  <ui-card :title="$t('incident.discussion')">
    <div ref="scrollView" class="border bg-gray-50 border-gray-200 rounded-md p-4 max-h-[400px] min-h-[300px] overflow-y-auto">
      <div
        class="flex items-center justify-start w-full"
        :class="incident.source_user?.id === userStore().getCurrentUser.id ? 'flex-row-reverse bg-opacity-50' : 'flex-row'"
      >
        <div class="w-full p-2 rounded-md max-w-[80%] bg-gray-200 border border-gray-300">
          <div
            class="flex items-center w-full"
            :class="incident.source_user?.id === userStore().getCurrentUser.id ? 'flex-row-reverse' : 'flex-row'"
          >
            <ui-avatar
              :firstname="incident.source_user?.first_name"
              :lastname="incident?.source_user?.last_name"
              :photo-url="incident.source_user?.first_name && incident.source_user?.last_name ? undefined : '/logo.jpg'"
              :class="incident.source_user?.id === userStore().getCurrentUser.id ? 'ml-2' : 'mr-2'"
            />
            <p class="text-gray-800 text-sm">
              {{
                $t('incident.created_this_incident', {
                  value: incident.source_user?.first_name
                    ? `${incident.source_user?.first_name} ${incident.source_user?.last_name}`
                    : 'NRJx',
                })
              }}
            </p>
          </div>

          <p
            :class="incident.source_user?.id === userStore().getCurrentUser.id ? 'text-end' : 'text-start'"
            class="text-xs mt-2 text-gray-500"
          >
            {{ dayjs(incident._created_at).format('DD/MM/YYYY HH:mm') }}
          </p>
        </div>
      </div>
      <div
        v-for="comment in incident.comments"
        :key="comment.id"
        class="flex items-center justify-start w-full mt-4"
        :class="comment._created_by === userStore().getCurrentUser.id ? 'flex-row-reverse' : 'flex-row'"
      >
        <div class="w-full p-2 rounded-md max-w-[80%] bg-gray-200 border border-gray-300">
          <div
            class="flex items-center w-full"
            :class="comment._created_by === userStore().getCurrentUser.id ? 'flex-row-reverse' : 'flex-row'"
          >
            <ui-avatar
              :firstname="getUser(comment._created_by)?.first_name"
              :lastname="getUser(comment._created_by)?.last_name"
              :photo-url="getUser(comment._created_by)?.first_name && getUser(comment._created_by)?.last_name ? undefined : '/logo.jpg'"
              :class="comment._created_by === userStore().getCurrentUser.id ? 'ml-2' : 'mr-2'"
            />
            <p class="text-gray-800 text-sm">{{ comment.content }}</p>
          </div>

          <p :class="comment._created_by === userStore().getCurrentUser.id ? 'text-end' : 'text-start'" class="text-xs mt-2 text-gray-500">
            {{ dayjs(comment._created_at).format('DD/MM/YYYY HH:mm') }}
          </p>
        </div>
      </div>
    </div>
    <div class="flex space-x-2 mt-2">
      <ui-form-input-text name="comment" hide-details :placeholder="$t('incident.your_comment')" @keyup.enter="comment" />
      <ui-button @click="comment" @keyup.enter="comment">{{ $t('incident.comment') }}</ui-button>
    </div>
  </ui-card>
</template>
